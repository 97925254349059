<template>
  <div id="general-details" class="details-section" v-if="shownDetailPopup">
    <div class="details-card-section blink">
      <div class="details-card-container">
        <div class="home-details-container">
          <span class="home-details">{{ homeDetailsSplit }}</span>
        </div>

        <div class="details-name-and-percent-container">
          <div class="details-name">
            {{this.details[this.shownDetailIndex][1].name}}
          </div>

          <div class="percent-details">
            <div class="home-progress" :style="{flex: this.details[this.shownDetailIndex][1].value.home}"></div>
            <div class="dot" :class="(Number(homeDetailsSplit) > Number(awayDetailsSplit)) ? 'home' : 'away'"></div>
            <div class="away-progress" :style="{flex: this.details[this.shownDetailIndex][1].value.away}"></div>
          </div>
        </div>

        <div class="away-detail-container">
          <span class="away-details">{{ awayDetailsSplit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventManager from "../../utils/eventManager";

export default {
  name: "Details",
  data() {
    return {
      eventManager: new EventManager(),
    }
  },
  props: {
    details: Array,
    shownDetailPopup: Boolean,
    shownDetailIndex: Number
  },
  computed: {
    homeDetailsSplit() {
      let details = this.details[this.shownDetailIndex][1].value.home.toString();
      let splitDetail = details.split("/")
      return splitDetail[splitDetail.length - 1]
    },
    awayDetailsSplit() {
      let details = this.details[this.shownDetailIndex][1].value.away.toString();
      let splitDetail = details.split("/")
      return splitDetail[splitDetail.length - 1]
    },
  }
}
</script>

<style scoped>

.home {
  background-color: #00003c;
}

.away {
  background-color: #FF0000;
}

.blink {
  animation: reveal .7s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.details-section {
  display: grid;
  place-items: center;
}

.details-card-section {
  display: flex;
  place-items: center;
  background-color: white;
  width: 80%;
  position: absolute;
  height: 4rem;
  bottom: 5px;
  z-index: 3;
}

.details-card-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.details-name-and-percent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.details-name {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 15px;
  font-weight: normal;
}

.home-details-container {
  display: flex;
}

.percent-details {
  display: flex;
  place-items: center;
  margin: auto;
  width: 95%;
}

.away-detail-container {
  display: flex;
}

.home-details {
  color: #00003c;
  font-size: 25px;
  font-weight: 700;
  padding-left: 20px;
}

.away-details {
  color: #FF0000;
  font-size: 25px;
  font-weight: 700;
  padding-right: 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 2px;
  flex: 0 0 10px;
}

.home-progress {
  display: flex;
  justify-content: center;
  place-items: center;
  height: 2px;
  background: #00003c;
  width: 100%;
}

.away-progress {
  display: flex;
  justify-content: center;
  place-items: center;
  height: 2px;
  background: #FF0000;
  width: 100%;
}

</style>