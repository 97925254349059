<template>
  <div class="position-cont">
    <div class="game-score-container">
      <div v-if="selectedEvent.type === 'score_change_tennis'" class="game-container">
        GAME
      </div>
      <div v-if="selectedEvent.type === 'periodscore'" class="game-container">
        SET
      </div>
      <div v-if="selectedEvent.type === 'match_ended'" class="game-container">
        MATCH ENDED
      </div>
      <div v-if="selectedEvent.type === 'score_change_tennis'" class="score-container">
        <div v-if="matchInfo.gamescore.home !== 50" :class="matchInfo.gamescore.home > matchInfo.gamescore.away ? 'score' : 'score2'" >{{matchInfo.gamescore.home}}</div>
        <div v-if="matchInfo.gamescore.home === 50" :class="matchInfo.gamescore.home > matchInfo.gamescore.away ? 'score' : 'score2'" >AD</div>
        <div class="score-a">:</div>
        <div v-if="matchInfo.gamescore.away !== 50" :class="matchInfo.gamescore.away > matchInfo.gamescore.home ? 'score' : 'score2'" >{{matchInfo.gamescore.away}}</div>
        <div v-if="matchInfo.gamescore.away === 50" :class="matchInfo.gamescore.away > matchInfo.gamescore.home ? 'score' : 'score2'" >AD</div>
      </div>

      <div v-if="selectedEvent.type === 'periodscore'" class="score-container">
        <div :class="selectedEvent.periodscore.home > selectedEvent.periodscore.away ? 'score' : 'score2'">{{selectedEvent.periodscore.home}}</div>
        <div class="score-a">:</div>
        <div  :class="selectedEvent.periodscore.away > selectedEvent.periodscore.home ? 'score' : 'score2'">{{selectedEvent.periodscore.away}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameScore",
  props: {
    matchInfo: Object,
    selectedEvent: Object
  }
}
</script>

<style scoped>

.position-cont {
  display: flex;
  place-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 5;
  animation: cssAnimation 0s ease-in 5s forwards, 1s fadeIn;
}

.game-score-container {
  display: flex;
  place-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 8px 16px;
  width: 164px;
  border-radius: 2px;
}

.score-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.game-container {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: normal;
}

.score {
  display: grid;
  place-items: center;
  font-size: 35px;
  font-weight: 500;
  width: 62px;
}

.score2 {
  display: grid;
  place-items: center;
  font-size: 35px;
  font-weight: 300;
  width: 62px;
}

.score-a {
  font-size: 35px;
  font-weight: 300;
}

@keyframes cssAnimation {
  to {
    width:0;
    height:0;
    top: 0;
    overflow:hidden;
    padding: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}


</style>