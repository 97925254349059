export const MAX_EVENT_COUNT = 5;
export const UNDEFINED_SECONDS = -1;

//sorted by priority
export const EVENT_TYPES = [
    'timeinfo',
    'periodscore',
    'ball_in_play',
    'score_change_tennis',
    'ball_in_play',
    'service_taken',
    'first_serve_fault',
    'match_ended',
    'Aces',
    'Double Faults',
    'Max Points in a Row',
    'Points won',
    '1st Serve Pts. Won',
    '2nd Serve Pts. Won',
    'Break Points Won',
    'Service Points Won',
    'Max Games in a Row',
    'Service Games Won',
    'Tiebreaks Won',
    '1st Serve Successful',
    'Receiver Points Won',
    '2nd Serve Successful'
];

export const EVENT_STATUSES = {
    0: {text: 'Maç Başlamadı', isLive: false, time: -1},
    31: {text: 'Devre Arası', isLive: false, time: 45},
    70: {text: 'Maç İptal', isLive: false, time: -1},
    100: {text: 'Maç Sona Erdi', isLive: false, time: -1},
}
