import {
    EVENT_STATUSES,
    EVENT_TYPES,
    MAX_EVENT_COUNT
} from "@/components/fv-lmt-tennis/utils/eventConstants";

class EventManager {
    match = null
    currentEvents = []
    detail = []
    specialEvents = []
    specialDetails = []
    selectedEvent = null
    selectedDetail = null
    time = -1
    status = null
    isLive = false

    resolve(match, events) {
        this.match = match
        this.currentEvents = events
        this.handleStatus()
        this.handleEvents(events)
    }

    resolveDetail(detailExtend) {
        //console.log(detailExtend)

        if (!detailExtend || detailExtend.length < 1) return;
        let asArray  = Object.entries(detailExtend)
        let details = asArray.filter(detail => EVENT_TYPES.includes(detail[1].name));
        console.log("DETAILS",details)
        this.details=details

    }

    handleEvents(eventList) {
        if (!eventList || eventList.length < 1) return;
        let events = eventList.filter(e => EVENT_TYPES.includes(e.type));
        for (let e = 0; e < events.length; e++) {
            let event = events[e];
            //console.log("EVVVV",event)
            for (let et = 0; et < EVENT_TYPES.length; et++) {
                let specialEventType = EVENT_TYPES[et];
                if (event.type === specialEventType) {
                    if (!this.specialEvents.find(e => e._id === event._id)) {
                        this.setSpecialEvent(event);
                    }
                    this.selectedEvent = event;
                    //console.log("SELECTED EVENTS",this.selectedEvent)
                    break;
                }
            }
        }
    }

    handleStatus() {
        let status = EVENT_STATUSES[this.match.status._id]
        if (status) {
            this.isLive = status.isLive;
            this.status = status.text;
            this.time = status.time;
            return
        }
        this.status = null;
        this.isLive = true;
        this.currentEvents.forEach(e => {
            if (e.time > this.time)
                this.time = e.time;
        })
    }

    setSpecialEvent(event) {
        this.specialEvents.push(event)
        if (this.specialEvents.length > MAX_EVENT_COUNT)
            this.specialEvents.shift()
    }

    setSpecialDetail(event) {
        this.specialDetails.push(event)
        if (this.specialDetails.length > MAX_EVENT_COUNT)
            this.specialDetails.shift()
    }
}

export default EventManager
