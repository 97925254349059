<template>
  <div class="pos-container">

    <!-- HOME DOWN SERVE -->

    <div v-if="matchInfo.gamescore.home === 0 || matchInfo.gamescore.home === 40 "
         class="home-position">

      <div class="blink_me" v-if="selectedEvent.type === 'score_change_tennis' && selectedEvent.team === 'home'">
        <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
      </div>

      <div v-if="selectedEvent.type === 'first_serve_fault'" class="serve-fault-container">
        <div v-if="selectedEvent.team === 'home'" class="serve-fault">
          FIRST SERVE FAULT
        </div>
      </div>
      <div v-if="selectedEvent.type === 'first_serve_fault'" class="second-serve-container">
        <div v-if="selectedEvent.team === 'home'" class="second-serve">
          SECOND SERVE
        </div>
      </div>

      <div v-if="selectedEvent.type === 'score_change_tennis'" class="point-container">
        <div v-if="selectedEvent.team === 'home'" class="point">
          POINT
        </div>
      </div>

      <div class="home-down-cross">
        <div class="blink_me object" v-if="selectedEvent.type === 'ball_in_play' && selectedEvent.team === 'home'">
          <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
        </div>
      </div>
      <div class="home-down-cross">
        <div class="blink_me object" v-if="selectedEvent.type === 'service_taken' && selectedEvent.team === 'home'">
          <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
        </div>
      </div>

      <div class="country-flag-and-name-container">
        <div class="flag-container">
          <v-img  class="country-flag"
                  :aspect-ratio="1"
                  :lazy-src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.home.cc.a2}.png`"
                  :src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.home.cc.a2}.png`"
                  alt=""
          />
        </div>
        <div class="home-athlete-name">
          {{ matchInfo.teams.home.name }}
        </div>
      </div>
    </div>

    <!-- HOME UP SERVE -->

    <div v-if=" matchInfo.gamescore.home ===15 ||matchInfo.gamescore.home === 30 || matchInfo.gamescore.home === 50" class="home-position2">

      <div>
        <div class="country-flag-and-name-container">
          <div class="flag-container">
            <v-img  class="country-flag"
                    :aspect-ratio="1"
                    :lazy-src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.home.cc.a2}.png`"
                    :src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.home.cc.a2}.png`"
                    alt=""
            />
          </div>
          <div class="home-athlete-name">
            {{ matchInfo.teams.home.name }}
          </div>
        </div>

        <div class="home-up-cross">
          <div class="blink_me object" v-if="selectedEvent.type === 'ball_in_play' && selectedEvent.team === 'home'">
            <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
          </div>
        </div>
        <div class="home-up-cross">
          <div class="blink_me object" v-if="selectedEvent.type === 'service_taken' && selectedEvent.team === 'home'">
            <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
          </div>
        </div>
      </div>

      <div v-if="selectedEvent.type === 'first_serve_fault'" class="serve-fault-container">
        <div v-if="selectedEvent.team === 'home'" class="serve-fault">
          FIRST SERVE FAULT
        </div>
      </div>
      <div v-if="selectedEvent.type === 'first_serve_fault'" class="second-serve-container">
        <div v-if="selectedEvent.team === 'home'" class="second-serve">
          SECOND SERVE
        </div>
      </div>

      <div v-if="selectedEvent.type === 'score_change_tennis'" class="point-container">
        <div v-if="selectedEvent.team === 'home'" class="point">
          POINT
        </div>
      </div>

      <div class="blink_me" v-if="selectedEvent.type === 'score_change_tennis' && selectedEvent.team === 'home'">
        <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
      </div>
    </div>

    <!-- AWAY UP SERVE -->

    <div v-if="matchInfo.gamescore.home === 0 || matchInfo.gamescore.home === 40"
         class="away-position">
      <div class="away-position-content">
        <div>
          <div class="country-flag-and-name-container-away">
            <div class="away-team-name-container">
              {{ matchInfo.teams.away.name }}
            </div>
            <div class="flag-container">
              <v-img  class="country-flag"
                      :aspect-ratio="1"
                      :lazy-src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.away.cc.a2}.png`"
                      :src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.away.cc.a2}.png`"
                      alt=""
              />
            </div>
          </div>


          <div class="away-up-cross">
            <div class="away-ball blink_me object-away"
                 v-if="selectedEvent.type === 'ball_in_play'  && selectedEvent.team === 'away'">
              <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
            </div>
          </div>
          <div class="away-up-cross">
            <div class="away-ball blink_me object-away"
                 v-if="selectedEvent.type === 'service_taken'  && selectedEvent.team === 'away'">
              <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
            </div>
          </div>
        </div>

        <div v-if="selectedEvent.type === 'first_serve_fault'" class="serve-fault-container away-serve">
          <div v-if="selectedEvent.team === 'away'" class="serve-fault">
            FIRST SERVE FAULT
          </div>
        </div>
        <div v-if="selectedEvent.type === 'first_serve_fault'" class="second-serve-container away-serve">
          <div v-if="selectedEvent.team === 'away'" class="second-serve">
            SECOND SERVE
          </div>
        </div>

        <div v-if="selectedEvent.type === 'score_change_tennis'" class="point-container away-point">
          <div v-if="selectedEvent.team === 'away'" class="point">
            POINT
          </div>
        </div>

        <div class="away-ball blink_me"
             v-if="selectedEvent.type === 'score_change_tennis' && selectedEvent.team === 'away'">
          <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
        </div>
      </div>
    </div>

    <!-- AWAY DOWN SERVE -->

    <div v-if=" matchInfo.gamescore.home === 15 || matchInfo.gamescore.home === 30 || matchInfo.gamescore.home === 50" class="away-position2">
      <div class="away-position-content2">

        <div class="away-ball blink_me"
             v-if="selectedEvent.type === 'score_change_tennis' && selectedEvent.team === 'away'">
          <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
        </div>

        <div v-if="selectedEvent.type === 'first_serve_fault'" class="serve-fault-container away-serve">
          <div v-if="selectedEvent.team === 'away'" class="serve-fault">
            FIRST SERVE FAULT
          </div>
        </div>
        <div v-if="selectedEvent.type === 'first_serve_fault'" class="second-serve-container away-serve">
          <div v-if="selectedEvent.team === 'away'" class="second-serve">
            SECOND SERVE
          </div>
        </div>

        <div v-if="selectedEvent.type === 'score_change_tennis'" class="point-container away-point">
          <div v-if="selectedEvent.team === 'away'" class="point">
            POINT
          </div>
        </div>

          <div class="away-down-cross">
            <div class="away-ball blink_me object-away"
                 v-if="selectedEvent.type === 'ball_in_play'  && selectedEvent.team === 'away'">
              <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
            </div>
          </div>
          <div class="away-down-cross">
            <div class="away-ball blink_me object-away"
                 v-if="selectedEvent.type === 'service_taken'  && selectedEvent.team === 'away'">
              <img src="https://img.icons8.com/color/20/000000/tennis-ball.png"/>
            </div>
          </div>

        <div class="country-flag-and-name-container-away">
          <div class="away-team-name-container">
            {{ matchInfo.teams.away.name }}
          </div>
          <div class="flag-container">
            <v-img  class="country-flag"
                    :aspect-ratio="1"
                    :lazy-src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.away.cc.a2}.png`"
                    :src="`https://img.sportradar.com/ls/crest/medium/${matchInfo.teams.away.cc.a2}.png`"
                    alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BallInPlay",
  components: {},
  props: {
    matchInfo: Object,
    selectedEvent: Object,
    type: String
  }
}
</script>

<style scoped>

.country-flag-and-name-container {
  display: flex;
}

.country-flag-and-name-container-away {
  display: flex;
  justify-content: flex-end;
}

.flag-container {
  display: grid;
  place-items: center;
}

.country-flag {
  width: 25px;
  height: 25px;
}

.home-athlete-name {
  margin-left: 10px;
}

.home-up-cross {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  transform: rotate(15deg);
  width: 50%;
  top: 30%;
}

.home-down-cross {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  transform: rotate(-15deg);
  width: 50%;
  bottom: 30%;
}

.away-up-cross {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  transform: rotate(-15deg);
  width: 50%;
  top: 30%;
}

.away-down-cross {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  transform: rotate(15deg);
  width: 50%;
  bottom: 30%;
}




@keyframes cssAnimation {
  to {
    width: 0;
    height: 0;
    top: 0;
    overflow: hidden;
    padding: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

/* BALL Blink */

.blink_me {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.pos-container {
  display: flex;
  justify-content: space-between;
  place-items: center;
  width: 100%;
  height: 58%;
  position: relative;
  overflow: hidden;
}

.home-position {
  color: rgba(255, 255, 255, .82);
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.home-position2 {
  color: rgba(255, 255, 255, .82);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.away-position {
  color: rgba(255, 255, 255, .82);
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.away-position-content {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.away-position2 {
  color: rgba(255, 255, 255, .82);
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  font-size: 20px;
}

.away-position-content2 {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  flex-direction: column;
}

.away-down-content-string {
  display: flex;
  justify-content: flex-end;
}

.away-team-name-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

/* BALL */

.away-ball {
  display: flex;
  justify-content: flex-end;
}

/* First Serve Fault */

.serve-fault-container {
  display: flex;
  animation: cssAnimation 0s ease-in 5s forwards, 1s fadeIn;
  margin: 3px 0;
}

.serve-fault {
  display: flex;
  height: 100%;
  background-color: white;
  color: black;
  font-size: 14px;
  border-radius: 2px;
  padding: 1px 8px;
}

.away-serve {
  justify-content: flex-end;
}

/* Second Serve */

.second-serve-container {
  display: flex;
  margin: 3px 0;
}

.second-serve {
  display: flex;
  height: 100%;
  background-color: white;
  color: black;
  font-size: 14px;
  border-radius: 2px;
  padding: 1px 8px;
}

/* Point */

.point-container {
  display: flex;
  animation: cssAnimation 0s ease-in 8s forwards, 1s fadeIn;
}

.point {
  height: 100%;
  background-color: white;
  color: black;
  font-size: 14px;
  border-radius: 2px;
  padding: 1px 8px;
}

.away-point {
  justify-content: flex-end;
}

.object {
  animation: MoveUpDown 1.2s linear infinite;
  position: relative;
  left: 0;
}

@keyframes MoveUpDown {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

.object-away {
  animation: MoveUpDownAway 1.2s linear infinite;
  position: relative;
  right: 0;
}

@keyframes MoveUpDownAway {
  0% {
    right: 0;
  }
  100% {
    right: 100%;
    opacity: 0;
  }
}

</style>