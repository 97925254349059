<template>
  <div>
    <div class="row">
      <v-col cols="12" md="6">
        <div class="fv-lmt-wrapper">

          <!--BG NOISE-->
          <div class="fv-lmt-bg-noise"></div>

          <!--OVERLAY Z-1-->
          <div class="fv-lmt-overlay"></div>

          <!--CONTENT Z-2-->
          <div class="fv-lmt-content-wrapper">
            <position-indicators :selected-event="selectedEvent" :match-info="matchInfo" :matchDetails="matchDetails" :statsCardsUpInfo="statsCardsUpInfo" :roundName="roundName"/>
          </div>

          <!-- Set Info Container -->

          <div class="set-info-container">
            <div v-if="matchInfo.status._id !== 100" class="set-info">
              Set {{matchInfo.p}}
            </div>
            <!--            <div v-if="selectedEvent.type === 'match_ended'" class="set-info">
                          <span class="result" > {{matchInfo.result.home}} </span>
                          <span class="result" > : </span>
                          <span class="result" > {{matchInfo.result.away}} </span>
                        </div>-->
          </div>


          <!--LINES-->
          <div class="fv-lmt-pitch-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 568 352"
                 style="stroke: rgb(255, 255, 255); margin-bottom: -5px">
            </svg>
            <div class="fv-lmt-tennis-pitch__pitch-lines">
              <div class="fv-lmt-tennis-pitch__sideline srm-top srm-left"
                   style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__sideline srm-btm srm-left"
                   style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__sideline srm-top srm-right"
                   style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__sideline srm-btm srm-right"
                   style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__center-service-line srm-left"
                   style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__center-service-line srm-right"
                   style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__service-line srm-left" style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__service-line srm-right" style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__midcourt-line" style="border-color: rgb(255, 255, 255);"></div>
              <div class="fv-lmt-tennis-pitch__boundary-line srm-is-transparent"
                   style="border-color: rgb(255, 255, 255);"></div>
            </div>
            <Details :shownDetailPopup="shownDetailPopup" :shownDetailIndex="shownDetailIndex" :details="eventManager.details"></Details>

            <svg viewBox="0 0 640 396" xmlns="http://www.w3.org/2000/svg" class="fv-lmt-tennis-pitch__svg srm-is-dark">
              <path stroke-width="20" opacity=".3" d="M321 55l10-5v294l-10-4z"></path>
              <path stroke-width="12" d="M331 51l2-1v295l-2-1z"></path>
              <path stroke-width="15"
                    d="M320 340l13 4v1.54a1 1 0 0 1-1.36.94L320 342v-2zM320 54l11.64-4.48a1 1 0 0 1 1.36.94V52l-13 4v-2z"></path>
            </svg>
          </div>
        </div>
      </v-col>
      <!--EVENTS-->
      <v-col cols="12" md="6">
        <event-monitor
            :all-events="allEvents"
            :filtered-events="events"
            :selected-event="selectedEvent"
            :match-status="matchStatus"
            :match-time="matchTime"
            :details="eventManager.details"
            :detailSelected="detailSelected"
            :shownDetailIndex="shownDetailIndex"
            :shownDetailPopup="shownDetailPopup"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventManager from "@/components/fv-lmt-tennis/utils/eventManager";
import * as dayjs from "dayjs";
import EventMonitor from "@/components/fv-lmt-tennis/components/EventMonitor";
import PositionIndicators from "@/components/fv-lmt-tennis/components/PositionIndicators";
import Details from "../components/Positions/Details";

export default {
  name: "LmtTennis",
  components: {PositionIndicators, EventMonitor, Details},
  data() {
    return {
      overlay: false,
      //match-info
      matchInfo: null,
      matchDetails: null,
      statsCardsUpInfo: null,
      roundName: null,
      //match
      match: null,
      //match details
      matchStatus: null,
      matchTime: -1,
      //match events
      selectedEvent: null,
      events: [],
      allEvents: [],
      //interval holder
      interval: null,
      bookMakerOdd: null,
      detailsExtended: null,
      allDetails: [],
      details: [],
      detailSelected: null,
      //timeout default = 4000ms
      timeout: 1000,
      eventManager: new EventManager(),
      shownDetailIndex: -1,
      shownDetailPopup: false
    }
  },
  mounted() {
    this.fetchMatchDetails();
    this.fetchFeed();
    this.fetchBookMakerOdds();
    this.fetchDetailsExtended();
    this.interval = setInterval(() => {
      this.fetchFeed();
      this.fetchBookMakerOdds();
    }, this.timeout);
    this.interval = setInterval(() => {
      this.fetchDetailsExtended();
    }, 60000);

    this.interval = setInterval(() => {
      this.shownDetailIndex = Math.floor(Math.random() * this.eventManager.details?.length)
    }, 5000);

    this.interval = setInterval(() => {
      this.shownDetailPopup = !this.shownDetailPopup
    }, 20000);
  },

  methods: {
    async fetchFeed() {
      const response = await axios.get(`https://lmt.fn.sportradar.com/common/tr/Etc:UTC/gismo/match_timelinedelta/${this.matchId}`);
      let match = response.data?.doc[0]?.data?.match;
      let allEvents = response.data?.doc[0]?.data?.events
      this.eventManager.resolve(match, allEvents)
      this.matchStatus = this.eventManager.status
      this.matchInfo = match;
      this.matchTime = this.eventManager.time
      this.selectedEvent = this.eventManager.selectedEvent
      this.events = this.eventManager.specialEvents
      this.allEvents = response.data?.doc[0]?.data?.events
    },
    //async fetchCardUpInfo() {
    //  const data = await axios.get(`https://lmt.fn.sportradar.com/demolmt/en/Europe:Moscow/gismo/sport_matches/5/2021-08-16`)
    //},

    async fetchMatchDetails () {
      const response = await axios.get(`https://lmt.fn.sportradar.com/demolmt/en/Etc:UTC/gismo/match_detailsextended/${this.matchId}`);
      let info = response.data?.doc[0]?.data
      this.matchDetails = info.values;
      await this.fetchStatsCardUpInfo();
    },
    async fetchStatsCardUpInfo() {
      const data = await axios.get(`https://widgets.fn.sportradar.com/demolmt/en/Etc:UTC/gismo/match_info/${this.matchId}`)
      this.statsCardsUpInfo = data.data?.doc[0]?.data.tournament
      this.roundName = data.data?.doc[0]?.data.match.roundname
    },
    async fetchBookMakerOdds() {
      const bookMakerOdd = await axios.get(`https://lmt.fn.sportradar.com/common/en/Etc:UTC/gismo/match_bookmakerodds/${this.matchId}`);
      this.bookMakerOdd = bookMakerOdd.data.doc[0].data[this.matchId];
      //console.log("Book Makers",this.bookMakerOdd);
    },
    async fetchDetailsExtended() {
      const detailsExt = await axios.get(`https://lmt.fn.sportradar.com/demolmt/en/Etc:UTC/gismo/match_detailsextended/${this.matchId}`);
      this.detailsExtended = detailsExt.data.doc[0].data.values;
      this.eventManager.resolveDetail(this.detailsExtended)
      //console.log("detailsExtended",this.detailsExtended);
      //this.details = this.detailsExtended
      //this.details = this.eventManager.specialDetails
      //this.detailSelected = this.eventManager.selectedDetail
    }
  },

  computed: {
    matchId() {
      return this.$route.params.id;
    },
  },

  filters: {
    formatDateTime: function (value) {
      return dayjs.unix(value).format('YYYY-MM-DD - HH:mm:ss');
    },
    formatSeconds: function (value) {
      if (value === -1) return null;
      return `${(value / 60).toFixed(0)}:${(value % 60)}`
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
}
</script>

<style scoped>

.set-info-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.set-info {
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: white;
  font-size: 14px;
  font-weight: 300;
  width: 45px;
  height: 18px;
  z-index: 3;
}


.fv-lmt-tennis-pitch__pitch-color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fv-lmt-tennis-pitch__pitch-color.srm-clay {
  background: #e37b52;
}

.fv-lmt-tennis-pitch__pitch-lines {
  position: absolute;
  left: 1.25%;
  right: 1.25%;
  top: 13.63%;
  bottom: 13.63%;
  opacity: .25;
}

.fv-lmt-tennis-pitch__sideline.srm-left {
  left: 0;
  right: 50%;
}

.fv-lmt-tennis-pitch__sideline.srm-top {
  top: 12.7%;
}

.fv-lmt-tennis-pitch__sideline.srm-right {
  right: 0;
  left: 50%;
}

.fv-lmt-tennis-pitch__sideline.srm-btm {
  bottom: 12.7%;
}

.fv-lmt-tennis-pitch__sideline {
  position: absolute;
  height: 2px;
  border-top-style: solid;
  border-top-width: 2px;
}


.fv-lmt-tennis-pitch__center-service-line.srm-right {
  right: 23.3%;
  left: 50%;
}

.fv-lmt-tennis-pitch__center-service-line.srm-left {
  left: 23.3%;
  right: 50%;
}

.fv-lmt-tennis-pitch__center-service-line {
  position: absolute;
  height: 2px;
  border-top-style: solid;
  border-top-width: 2px;
  top: 50%;
}


.fv-lmt-tennis-pitch__service-line.srm-right {
  right: 23.3%;
}

.fv-lmt-tennis-pitch__service-line.srm-left {
  left: 23.3%;
}

.fv-lmt-tennis-pitch__service-line {
  position: absolute;
  border-left-style: solid;
  border-left-width: 2px;
  width: 2px;
  top: 12.7%;
  bottom: 12.7%;
}

.fv-lmt-tennis-pitch__midcourt-line {
  position: absolute;
  left: 0;
  right: 0;
  margin: 2px auto;
  top: 0;
  bottom: 0;
  width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
}

.fv-lmt-tennis-pitch__boundary-line {
  border-width: 2px;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.srm-is-transparent {
  background: transparent !important;
}

.fv-lmt-tennis-pitch__texture {
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


svg {
  fill: #000;
}

.fv-lmt-tennis-pitch__svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.fv-lmt-wrapper {
  position: relative;
  background-color: #e37b52;
  background-repeat: repeat;
}

.fv-lmt-bg-noise {
  background-image: url("../../../assets/lmt-v2/lmt-substructure.png");
  background-size: 40px 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-repeat: initial;
}

.fv-lmt-stripes {
  position: absolute;
  left: 6.7%;
  right: 1.8%;
  top: 3%;
  bottom: 3%;
  background: repeating-linear-gradient(
      to right,
      hsla(0, 0%, 100%, .08),
      hsla(0, 0%, 100%, .08) 6.66%,
      transparent 6.66%,
      transparent 13.33%
  );
}

.fv-lmt-overlay {
  position: absolute;
  background: -webkit-radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
  background: radial-gradient(ellipse closest-corner at 50% 50%, transparent 0, rgba(0, 0, 0, .15) 100%);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.fv-lmt-content-wrapper {
  position: absolute;
  left: 1.8%;
  right: 1.8%;
  top: 3%;
  bottom: 3%;
  z-index: 2;
}

.fv-lmt-pitch-wrapper {
  width: 100%;
  height: 100%;
}
</style>
