<template>
  <div class="position-container">
    <div class="delay-card-container" v-if="selectedEvent.type === 'periodscore' || matchInfo.status._id === 100">
     <statistics :matchInfo="matchInfo" :matchDetails="matchDetails" :statsCardsUpInfo="statsCardsUpInfo" :roundName="roundName"></statistics>
    </div>

      <game-score v-if="type === 'score_change_tennis' || type === 'periodscore' || type === 'match_ended'" :match-info="matchInfo" :selectedEvent="selectedEvent"></game-score>
      <ball-in-play :type="type" :match-info="matchInfo" :selectedEvent="selectedEvent"></ball-in-play>
  </div>
</template>

<script>

import GameScore from "./Positions/GameScore";
import BallInPlay from "./Positions/BallInPlay";
import Statistics from "./Statistics";

export default {
  name: "PositionIndicators",
  components: {
    GameScore,
    BallInPlay,
    Statistics,
  },
  props: {
    selectedEvent: Object,
    matchInfo: Object,
    matchDetails: Object,
    statsCardsUpInfo: Object,
    roundName: Object,
  },
  data() {
    return {
      type: null
    }
  },
  methods: {
    handleEventChange(value) {
      if (!value) {
        return
      }

      const type = value?.type;
      if (type) {
        this.type = type
      }
    }
  },
  watch: {
    selectedEvent(value) {
      this.handleEventChange(value)
    }
  }
}
</script>

<style scoped>

.delay-card-container {
  position: absolute;
  display: flex;
  place-items: center;
  width: 100%;
  height: 100%;
  animation: 6s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.position-container {
  display: flex;
  place-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.home-position-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: .25s;
  transform: translateX(-100%);
}

.away-position-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: .25s;
  transform: translateX(100%);
}

.home-position-slider.hidden {
  transform: translateX(-100%);
}

.home-position-slider.low {
  transform: translateX(-44%);
}

.home-position-slider.mid {
  transform: translateX(-30%);
}

.home-position-slider.high {
  transform: translateX(-15%);
}

.away-position-slider.hidden {
  transform: translateX(100%);
}

.away-position-slider.low {
  transform: translateX(44%);
}

.away-position-slider.mid {
  transform: translateX(30%);
}

.away-position-slider.high {
  transform: translateX(15%);
}
</style>
