<template>
  <div class="match-details-info-section">
    <div class="general-container">

      <div class="match-statistics">
        <div class="match-statistics-section">
          <div>
            MATCH STATISTICS
          </div>
        </div>

        <hr>
      </div>

      <div class="info-container" v-if="matchDetails[130].value || matchDetails[132].value || serveWonHome || breakpointsHome">
        <!-- ACES -->

          <div class="number-container">
            <span class="home-number">{{matchDetails[130].value.home}}</span>
            <span class="font-size" style="font-weight: normal">ACES</span>
            <span class="away-number">{{matchDetails[130].value.away}}</span>
          </div>

          <div class="home-percent">
            <div class="progress" :style="{flex: matchDetails[130].value.home}">
            </div>
            <div class="away-percent" :style="{flex: matchDetails[130].value.away}">
            </div>
          </div>

        <!-- DOUBLE FAULTS -->

        <div class="number-container">
          <span class="home-number">{{matchDetails[132].value.home}}</span>
          <span class="font-size" style="font-weight: normal">DOUBLE FAULTS</span>
          <span class="away-number">{{matchDetails[132].value.away}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: matchDetails[132].value.home}">

          </div>
          <div class="away-percent" :style="{flex: matchDetails[132].value.away}">
          </div>
        </div>

        <!-- 1ST SERVE PTS WON -->

        <div class="number-container">
          <span class="home-number">{{serveWonHome}}</span>
          <span class="font-size" style="font-weight: normal">1ST SERVE PTS WON</span>
          <span class="away-number">{{serveWonAway}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: serveWonHome}">

          </div>
          <div class="away-percent" :style="{flex: serveWonAway}">
          </div>
        </div>

        <!-- BREAK POINTS WON -->

        <div class="number-container">
          <span class="home-number">{{breakpointsHome}}</span>
          <span class="font-size" style="font-weight: normal">BREAK POINTS WON</span>
          <span class="away-number">{{breakpointsAway}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: breakpointsHome}">

          </div>
          <div class="away-percent" :style="{flex: breakpointsAway}">
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "MatchDetails",
  props: {
    matchDetails: Object
  },
  data() {
    return {
      serveWonHome: this.matchDetails[137].value.home.replace("\\" , "").split("/")[0],
      serveWonAway: this.matchDetails[137].value.away.replace("\\" , "").split("/")[0],
      breakpointsHome: this.matchDetails[139].value.home.replace("\\" , "").split("/")[0],
      breakpointsAway: this.matchDetails[139].value.away.replace("\\" , "").split("/")[0],
    }
  }
}
</script>

<style scoped>

.match-statistics {
  display: flex;
  flex-direction: column;
}

hr {
  height: 1.5px;
  background-color: #dedede;
  border: none;
}

.match-details-info-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.general-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  height: 95%;
  z-index: 2;
  padding: 10px 0;
}

.match-statistics-section {
  font-weight: bolder;
  font-size: 12px;
  padding-bottom: 3px;
}

.info-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 6px;
}

.number-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.home-percent{
  display: flex;
  place-items: center;
  justify-content: space-between;
  width: 100%;
}

.font-size {
  font-size: 13px;
}

.progress{
  display: flex;
  height: 3px;
  background: darkblue;
}

.away-percent {
  display: flex;
  height: 3px;
  background: red;
}

.home-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 13px;
  color: darkblue;
}

.away-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 13px;
  color: red;
}

</style>