<template>
  <div class="carousel">
    <div class="carousel-items-container">
      <div class="carousel-item item-1"> <day-and-set-info :matchInfo="matchInfo" :statsCardsUpInfo="statsCardsUpInfo" ></day-and-set-info> </div>
      <div class="carousel-item item-2"> <prize-info :matchInfo="matchInfo" :statsCardsUpInfo="statsCardsUpInfo" :roundName="roundName"></prize-info> </div>
      <div class="carousel-item item-3"> <match-details  :matchDetails="matchDetails" ></match-details> </div>
    </div>
    <div class="carousel-buttons-container">
      <div class="carousel-button button-1" v-on:click="selectPage(0)"></div>
      <div class="carousel-button button-2" v-on:click="selectPage(1)"></div>
      <div class="carousel-button button-3" v-on:click="selectPage(2)"></div>
    </div>
  </div>
</template>

<script>
import DayAndSetInfo from "./Statistics/DayAndSetInfo";
import PrizeInfo from "./Statistics/PrizeInfo";
import MatchDetails from "./Statistics/MatchDetails";

export default {
  name: "Statistics",
  components: {
    DayAndSetInfo,
    PrizeInfo,
    MatchDetails
  },
  props: {
    matchInfo: Object,
    matchDetails: Object,
    statsCardsUpInfo: Object,
    roundName: Object
  },
  data() {
    return {
      currentPage: 0
    }
  },
  methods: {
    selectPage(pageNumber){
      this.currentPage = pageNumber
      const items = document.getElementsByClassName("carousel-item");
      for(let i = 0; i < items.length; i += 1) {
        const marginMultiplier = (i - pageNumber);
        const left = (marginMultiplier * 100) + '%';
        items[i].style['margin-left'] = left;
      }
      const buttons = document.getElementsByClassName("carousel-button");
      for(let i = 0; i < items.length; i += 1) {
        buttons[i].style['background'] = pageNumber === i ? 'red' : 'white';
      }
    }
  },
  mounted() {
    const itemsCount = document.getElementsByClassName("carousel-item").length;

    this.selectPage(0);
    setInterval(() => {
      this.currentPage = (this.currentPage +1) % itemsCount
      this.selectPage(this.currentPage)
    }, 8000)
  }
}
</script>

<style scoped>

.carousel {
  width: 55%;
  height: 55%;
  overflow: hidden;
  margin: 0 auto;
}

.carousel-items-container {
  position: relative;
  height: 90%;
}

.carousel-item {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: margin .3s;
}


.item-1 {
  background: white;
}

.item-2 {
  margin-left: 100%;
  background: white;
}

.item-3 {
  margin-left: 200%;
  background: white;
}

.carousel-buttons-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.carousel-button {
  margin: 0 3px;
  border: 1px solid white;
  background: #555;
  width: 25px;
  height: 5px;
}

</style>