<template>
  <div class="day-and-set-info-section">

    <div class="general-container">

      <div class="day-section">
        <div class="man-or-women-container">
            <span style="font-weight: 500; font-size: 12px">{{statsCardsUpInfo.tennisinfo.city}}</span>  <span style="font-size: 12px; font-weight: 300">{{statsCardsUpInfo.tennisinfo.country}} | {{statsCardsUpInfo.tennisinfo._gender.charAt(0).toUpperCase() + statsCardsUpInfo.tennisinfo._gender.slice(1)}} - {{statsCardsUpInfo.tennisinfo._type.charAt(0).toUpperCase() + statsCardsUpInfo.tennisinfo._type.slice(1)}}</span>
        </div>
        <hr>
        <div class="day-info">
          {{ matchInfo._dt.date}}
        </div>
      </div>

      <div class="set-section">
        <div class="set-info">
          <span v-if="matchInfo.status._id === 8" > 1st Set </span>
          <span v-if="matchInfo.status._id === 9" > 2nd Set </span>
          <span v-if="matchInfo.status._id === 100" > Match Ended </span>
        </div>
        <hr>

        <div class="team-name-container">
          <div class="team-name">
            <div class="name">{{matchInfo.teams.home.name}}</div>
            <div class="result-container">
              <span class="period-result" v-if="matchInfo.periods.p1" > {{matchInfo.periods.p1.home}} </span>
              <span class="period-result" v-if="matchInfo.periods.p2" > {{matchInfo.periods.p2.home}} </span>
              <span class="period-result" v-if="matchInfo.periods.p3" > {{matchInfo.periods.p3.home}} </span>
              <div class="match-result">{{matchInfo.result.home}} </div>
            </div>
          </div>
          <div class="team-name">
            <div class="name">{{matchInfo.teams.away.name}}</div>
            <div class="result-container">
              <span class="period-result" v-if="matchInfo.periods.p1" > {{matchInfo.periods.p1.away}} </span>
              <span class="period-result" v-if="matchInfo.periods.p2" > {{matchInfo.periods.p2.away}} </span>
              <span class="period-result" v-if="matchInfo.periods.p3" > {{matchInfo.periods.p3.away}} </span>
              <div class="match-result">{{matchInfo.result.away}} </div>
            </div>
          </div>
        </div>

        <hr>
      </div>

      <div class="fv-seciton">
        <v-img src="@/assets/lmt/img-label-fv@2x.png" class="fv-img" contain/>
      </div>
    </div>

  </div>

</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DayAndSetInfo",
  props: {
    matchInfo: Object,
    statsCardsUpInfo:Object
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('YYYY-MM-DD - HH:mm')
    },
  }
}
</script>

<style scoped>

hr {
  height: 1.5px;
  background-color: #dedede;
  border: none;
}

.day-and-set-info-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.general-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  z-index: 2;
}

.day-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 0;
}

.set-info {
  font-size: 13px;
  font-weight: 300;
}

.team-name-container {
  padding: 5px 0;
}

.team-name {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.name {
  font-weight: normal;
  font-size: 12px;
}

.result-container {
  display: flex;
  justify-content: space-between;
}

.period-result {
  padding: 0 5px;
  font-weight: 300;
}

.match-result {
  display: grid;
  place-items: center;
  width: 15px;
  height: 16px;
  color: white;
  margin: 0 5px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  background-color: red;
}

.fv-seciton {
  display: grid;
  place-items: center;
  justify-content: center;
}

.fv-img {
  width: 60%;
  height: 70%;
}

</style>